import { Component, OnInit } from '@angular/core';
import { faMapMarkerAlt  } from '@fortawesome/free-solid-svg-icons';
import { faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';
@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit {
  mapMarker = faMapMarkerAlt;
  twitter = faTwitter;
  instagram = faInstagram;
  constructor() { }

  ngOnInit() {
  }

}
