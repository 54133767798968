import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit {
  slides = [
    {img: "http://placehold.it/350x150/000000", name: 'bla'},
    {img: "http://placehold.it/350x150/111111", name: 'bla'},
    {img: "http://placehold.it/350x150/333333", name: 'bla'},
    {img: "http://placehold.it/350x150/333333", name: 'bla'},
    {img: "http://placehold.it/350x150/333333", name: 'bla'},
    {img: "http://placehold.it/350x150/333333", name: 'bla'},
    {img: "http://placehold.it/350x150/333333", name: 'bla'},
    {img: "http://placehold.it/350x150/666666", name: 'bla'}
  ];
  slideConfig = {
    centerPadding: '60px',
    slidesToShow: 4,
    lazyLoad: 'ondemand',
    arrows: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: true,
          centerMode: true,
          centerPadding: '40px',
          slidesToShow: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: '40px',
          slidesToShow: 1
        }
      }
    ]
  };
  
  constructor() { }

  ngOnInit() {
  }

}
