import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-second-midle-section',
  templateUrl: './second-midle-section.component.html',
  styleUrls: ['./second-midle-section.component.scss']
})
export class SecondMidleSectionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
